jQuery(document).ready(function( $ ) {
	
	// Change tab class and display content
	$(document).on('click', '.tabs a', function(){
		event.preventDefault();
		
		// Update Nav
		$('.tabs li').removeClass('is-active');
		$(this).parent().addClass('is-active');

		// Update Panels
		$('.tabs-panel').removeClass('is-active');
		$($(this).attr('href')).addClass('is-active');
		
	});

});