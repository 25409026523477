jQuery(document).ready(function( $ ) {

	// Add GTM Data Layer if not already defined
	window.dataLayer = window.dataLayer || [];

	$(document).on({
	    'DOMNodeInserted': function() {
	        $('.pac-item, .pac-item span', this).addClass('needsclick');
	    }
	}, '.pac-container');

	$(document).ready(function(){

		/**
	     * Init Google Map
	     */
	    var map,
	    	listings_html  = '',
	    	search_radius, // default search radius when search by geocoding or geolocation
	    	allMarkers = [],
	    	allMarkersData = [],
	    	search_on_scroll = false,
	    	search_by_geo = false,
	    	defaultZoom = (window.matchMedia("(min-width: 900px)").matches) ? 10 : 8,
	        bounds,
	        geocoder = new google.maps.Geocoder(),
	        markerPath = "M648 1169q117 0 216 -60t156.5 -161t57.5 -218q0 -115 -70 -258q-69 -109 -158 -225.5t-143 -179.5l-54 -62q-9 8 -25.5 24.5t-63.5 67.5t-91 103t-98.5 128t-95.5 148q-60 132 -60 249q0 88 34 169.5t91.5 142t137 96.5t166.5 36zM652.5 974q-91.5 0 -156.5 -65 t-65 -157t65 -156.5t156.5 -64.5t156.5 64.5t65 156.5t-65 157t-156.5 65z",
	        currentMarkerOptions,
	        currentSearchPosition,
	        dealers_data,
	        currentMarker,
	        currentLocationInfobox = new InfoBox({
		        content: 'Your location',
		        boxClass: 'a-infobox a-infobox--current-location',
		        pixelOffset: new google.maps.Size(30, -38),
		        boxStyle: {
		            padding: "10px"
		        },
		        disableAutoPan: true,	
		        closeBoxURL: "",
		        infoBoxClearance: new google.maps.Size(1, 1),
		        isHidden: false,
		        pane: "floatPane",
		        enableEventPropagation: false
		    }),
		    infobox = new InfoBox({
		        content: '',
		        boxClass: 'a-infobox',
		        pixelOffset: new google.maps.Size(30, -38),
		        boxStyle: {
		            padding: "10px"
		        },
		        disableAutoPan: true,	
		        closeBoxURL: "",
		        infoBoxClearance: new google.maps.Size(1, 1),
		        isHidden: false,
		        pane: "floatPane",
		        enableEventPropagation: false
		    })
		    ;

	    function sm_initMap() {
	        map = new google.maps.Map(document.getElementById('js-dealer-map'), {
	            minZoom: 4,
	            mapTypeControl: false,
	            streetViewControl: false,
	         	fullscreenControl: false,
	         	zoomControlOptions: {
	              	position: google.maps.ControlPosition.TOP_RIGHT
	          	},
	            styles: [
	            	{
	                	stylers: [{ saturation: -90 }]
	            	},
	            	{
		                featureType: "poi",
		                elementType: "labels",
		                stylers: [{ "visibility": "off" }]
	           	 	}
	            ],
	            scrollwheel: false
	        });

	        currentMarkerOptions = {
	            icon: {
	                path: markerPath,
	                rotation: 180,
	                scale: 0.035,
	                strokeWeight: 0,
	                fillOpacity: 1,
	                fillColor: "#da4b42",
	                anchor: new google.maps.Point(700, 0)
	            },

	            clickable: false,
	            map: map,
	        };

	        // Share my Location
	       	sm_setDefaultLocation(map);

	       	// Search function
	        sm_searchAutoComplete(map, currentSearchPosition);

	    }

	   	function sm_setDefaultLocation(map) {
	   		if (navigator.geolocation) {
	            navigator.geolocation.getCurrentPosition(function(position) {
	                var pos = {
	                    lat: position.coords.latitude,
	                    lng: position.coords.longitude
	                };
	                map.setZoom(defaultZoom);
	                search_by_geo = true;

		        	currentMarkerOptions.position = new google.maps.LatLng(pos);
				    currentMarker = new google.maps.Marker(currentMarkerOptions);
				    map.setCenter(currentMarkerOptions.position); 

				    setTimeout(function(){
			    		currentLocationInfobox.open(map, currentMarker);
			    	},200);

			    	$('#js-dealer-search').val('Current Location');
			    	$('.js-clear-search').addClass('is-active');

				    sm_getDealers(new google.maps.LatLng(pos), 60,function(data) {	
		        		dealers_data = data;
		        		
		        		if (dealers_data) {
			        		sm_sortDealers(dealers_data, new google.maps.LatLng(pos));
			        		sm_buildDealersMarkers(dealers_data);
			        		sm_buildDealersListings(allMarkersData);
			        	}
			        	search_by_geo = false;
		        	});

	            }, function() {
	            	bounds = new google.maps.LatLngBounds()
	              	bounds.extend(new google.maps.LatLng(66.989295, -140.420091));
		            bounds.extend(new google.maps.LatLng(28.655277, -66.907718));        
		            map.fitBounds(bounds);
		            
	            });

	        } else {
	        	bounds = new google.maps.LatLngBounds()
	            bounds.extend(new google.maps.LatLng(66.989295, -140.420091));
		        bounds.extend(new google.maps.LatLng(28.655277, -66.907718));         
	            map.fitBounds(bounds);
	        }
	   	}

	   	function sm_searchAutoComplete(map) {
	   		var input = document.getElementById('js-dealer-search');
		  	var autocomplete = new google.maps.places.Autocomplete(input, { types: ['geocode'] });

		  	// Bias the SearchBox results towards current map's viewport.
		  	autocomplete.bindTo('bounds', map);

		  	// Listen for the event fired when the user selects a prediction and retrieve
		  	// more details for that place.
		 	autocomplete.addListener('place_changed', function() {
		 		search_on_scroll = false;
		    	var place = autocomplete.getPlace();

		    	if (currentMarker && currentMarker.setMap) {
				    currentMarker.setMap(null);
				}

		    	if ( place.length == 0 ) {
		      		return;
		    	}
				
				if (place.hasOwnProperty('formatted_address')) {
					var search_query = place.formatted_address;
				} else {
					var search_query = place.name;
				}

				var map_searched = $('#js-dealer-map').attr('data-searched'),
					search_query_event 		= new CustomEvent('dealerMapQuery', { detail: search_query });

					if (map_searched == 0) {

						document.getElementById("js-dealer-map").addEventListener('dealerMapQuery', function (e) {  

							window.dataLayer.push({
								'event' : 'dealerMapSearch',
								'dealerMapSearchValue': search_query
							});

					}, false);

				}

				$('#js-dealer-map').attr('data-searched', 1);

				document.getElementById("js-dealer-map").dispatchEvent(search_query_event);

		    	if(!place.geometry){
					geocoder.geocode( {address:place.name}, function(results, status) {
						if (status == google.maps.GeocoderStatus.OK) {
							map.setZoom(defaultZoom);
							search_by_geo = true;
							$('.js-clear-search').addClass('is-active');

							currentMarkerOptions.position = results[0].geometry.location;
				        	currentMarker = new google.maps.Marker(currentMarkerOptions);
				        	map.setCenter(currentMarkerOptions.position);
				        	
				        	setTimeout(function(){
					    		currentLocationInfobox.open(map, currentMarker);
					    	},200);

				        	sm_getDealers(results[0].geometry.location, 60, function(data) {

				        		dealers_data = data;
				        	});

				        	if (dealers_data) {
				        		sm_sortDealers(dealers_data, results[0].geometry.location);
				        		sm_buildDealersMarkers(dealers_data, results[0].geometry.location);
				        		sm_buildDealersListings(allMarkersData);
				        	}
				        	search_by_geo = false;
						} 
					});
				} else if (place.geometry && place.geometry.location) {
		        	map.setZoom(defaultZoom);
		        	search_by_geo = true;
		        	$('.js-clear-search').addClass('is-active');

		        	currentMarkerOptions.position = place.geometry.location;
				    currentMarker = new google.maps.Marker(currentMarkerOptions);
				    map.setCenter(currentMarkerOptions.position);

			    	setTimeout(function(){
			    		currentLocationInfobox.open(map, currentMarker);
			    	},200);

			    	// var event = new CustomEvent('dealer_map_query', { search_query: place.formatted_address });
			    	// elem.dispatchEvent(event);

				    sm_getDealers(place.geometry.location, 60,function(data) {	
		        		dealers_data = data;
		        		
		        		if (dealers_data) {
			        		sm_sortDealers(dealers_data, place.geometry.location);
			        		sm_buildDealersMarkers(dealers_data);
			        		sm_buildDealersListings(allMarkersData);
			        	}
			        	search_by_geo = false;
		        	});	        	
				}
	      	
	      		
		  	});

		  	if (navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
			    setTimeout(function() {
			        var container = document.getElementsByClassName('pac-container')[0];
			        container.addEventListener('touchend', function(e) {
			            e.stopImmediatePropagation();
			        });
			    }, 500);
			}
	   	}

	   	function sm_getDealers(position, radius, callback) {

	   		$('.js-new-search').remove();
	   		$.ajax({
				url: wp_data.ajax_url + '?action=get_dealers&lat=' + position.lat() + '&lng=' + position.lng() + '&radius=' + radius,
				type: 'POST',			
				success:function(data){
					callback(data.dealer_listings);
				}
			});
	   	}

	   	function distance(lat1, lon1, lat2, lon2) {
	        var p = 0.017453292519943295;    // Math.PI / 180
	        var c = Math.cos;
	        var a = 0.5 - c((lat2 - lat1) * p)/2 + 
	                c(lat1 * p) * c(lat2 * p) * 
	                (1 - c((lon2 - lon1) * p))/2;

	        return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
	    }

	    function sm_compareDistance(a,b) {
	        if (a.distance < b.distance)
	            return -1;
	        if (a.distance > b.distance)
	            return 1;
	        return 0;
	    }

	   	function sm_sortDealers(data, current_location) {
	   		if (current_location) {
	   			for (var i = 0; i < data.length; i++) {
	   				data[i]['distance'] = distance(current_location.lat(), current_location.lng(), data[i]['lat'], data[i]['lng']); 
	   			}
	   			data.sort(sm_compareDistance);
	   		}	
	   	}

	   	function sm_isOdd(num) { return num % 2;}

	   	function sm_buildDealersMarkers(data) {
	   		if (search_by_geo) {
	   			allMarkers     = [];
	   			allMarkersData = [];
	   		}

	   		var newMarkers = [];

	   		for (var i = 0; i < data.length; i++) {
	   			const record = data[i];

	   			var latLng  = new google.maps.LatLng(record['lat'], record['lng']);

	   			if (latLng) {
	   				var duplicate_marker = false;

	   				if (allMarkers && allMarkers.length > 0) {
		   				for (n = 0; n < allMarkers.length; n++) {
		                    if (latLng.equals(allMarkers[n].getPosition())) {
		                        duplicate_marker = true;

		                        break;
		                    }
		                }
		            }

	                if (!duplicate_marker) {
		   				var marker = new google.maps.Marker({
		                    position: latLng,
		                    icon: {
				                path: markerPath,
				                rotation: 180,
				                scale: 0.035,
				                strokeWeight: 0,
				                fillOpacity: 0.9,
				                fillColor: "rgb(0,0,0)",
				                anchor: new google.maps.Point(700, 0)
				            },
				            clickable: true,
				            map: map,
		                });

		   				newMarkers.push(marker);
		                allMarkers.push(marker);  
		                allMarkersData.push(record);
		            }
	   			}   			
	   		}

	   		for (var i = 0; i < allMarkers.length; i++) {

	   			google.maps.event.addListener(allMarkers[i], 'mouseover', function() {
	   				var index = allMarkers.indexOf(this);
	   				name = allMarkersData[index].name ? allMarkersData[index].name : false;
	   				if (name) {
		   				infobox.setContent(name);
				    	infobox.open(map, this);
				    }
	   			});

	   			google.maps.event.addListener(allMarkers[i], 'mouseout', function() {
	   				infobox.close();
	   			});

	   			google.maps.event.addListener(allMarkers[i], 'click', function() {
	   				var index = allMarkers.indexOf(this),
	   					dealer_html = '',
	   					name = allMarkersData[index].name ? allMarkersData[index].name : false,
		   				phone = allMarkersData[index].phone ? allMarkersData[index].phone : false,
		   				address = allMarkersData[index].address ? allMarkersData[index].address : false,
		   				city = allMarkersData[index].city ? allMarkersData[index].city + ', ' : false,
		   				state_code = allMarkersData[index].state_code ? allMarkersData[index].state_code + ' ' : false,
		   				postal = allMarkersData[index].postal ? allMarkersData[index].postal : false,
		   				website = allMarkersData[index].website ? allMarkersData[index].website : false,
		                directionsLink = false;

		                if (allMarkersData[index].lat && allMarkersData[index].lng) {
		                    var fromLocation = "Current+Location";
		                    if (this.locationMarker) {
		                        var fromPos = this.locationMarker.getPosition();
		                        fromLocation = fromPos.lat() + "," + fromPos.lng();
		                    }
		                    directionsLink = "https://www.google.com/maps/dir/" + fromLocation + "/" + allMarkersData[index].lat + "," + allMarkersData[index].lng;
		                }

	   				if (whatInput.ask('itent') == 'touch') {
	   					infobox.close();
		   				if (name) {
			   				infobox.setContent(name);
					    	infobox.open(map, this);
					    }
	   				}

	   				dealer_html += '<div class="m-dealer js-dealer" data-marker-id="' + i + '">';
		            	dealer_html += '<h3>' + name + '</h3>';
		            	if (phone) {
		            		dealer_html += '<a class="gtm-find-a-dealer-link gtm-rm-tel" data-dealer-name="' + name +'" href="tel:' + phone.replace(/\D/g,'') + '"><i class="fa fa-phone" aria-hidden="true"></i>' + phone + '</a>';
		            	}
		            	if (website) {
		            		dealer_html += '<a class="gtm-find-a-dealer-link" data-dealer-name="' + name +'" target="_blank" href="' + website + '"><i class="fa fa-star" aria-hidden="true"></i>Website</a>';
		            	}
		            	dealer_html += '<p>';
		            		if (address) {
		            			dealer_html += address + '<br>';
		            		}
		            		
		            		dealer_html += (city || '') + (state_code || '') + (postal || '');
		            		
		            	dealer_html += '</p>';
		            	if (directionsLink) {
		            		dealer_html += '<a class="gtm-find-a-dealer-link" data-dealer-name="' + name +'" target="_blank" href="' + directionsLink + '"><i class="fa fa-share" aria-hidden="true"></i>Directions</a>';
		            	}
		            dealer_html += '</div>'; 
		           	
		           	if (listings_html != '') {	
		           	 	dealer_html  += '<button class="a-back-to-results-button js-back-to-results"><i class="fa fa-chevron-left" aria-hidden="true"></i>back to results</button>';
		           	}

		            $('.js-dealer-list').html(dealer_html);
	   			});

	   			google.maps.event.addListener(allMarkers[i], 'mouseover', function() {
	   				var index = allMarkers.indexOf(this);
	   				name = allMarkersData[index].name ? allMarkersData[index].name : false;
	   				if (name) {
		   				infobox.setContent(name);
				    	infobox.open(map, this);
				    }
	   			});

	   			google.maps.event.addListener(allMarkers[i], 'mouseout', function() {
	   				infobox.close();
	   			});
	   		}
	   		var markerCluster = new MarkerClusterer(map, newMarkers,
	            {imagePath: wp_data.plugin_url + '/assets/images/m'});
	   	}

	   	function sm_buildDealersListings(data) {
	   		var tab_size = 3,
	   			total_pages = Math.ceil(data.length/tab_size),
	   			current_tab = 1,
	   			dealer_count 	 = 0;

	   		listings_html = '';

	   		if ( total_pages > 1 ) {	
	   			listings_html += '<div class="tabs-content" data-tabs-content="listings-tab"><div class="tabs-panel is-active" id="' + current_tab + '">';
	   		}


	   		for (var i = 0; i < data.length; i++) {
	   			const record = data[i];

	   			var name = record.name ? record.name : false,
	   				phone = record.phone ? record.phone : false,
	   				address = record.address ? record.address : false,
	   				city = record.city ? record.city + ', ' : false,
	   				state_code = record.state_code ? record.state_code + ' ' : false,
	   				postal = record.postal ? record.postal : false,
	   				website = record.website ? record.website : false,
	                directionsLink = false;

	                if (record.lat && record.lng) {
	                    var fromLocation = "Current+Location";
	                    if (this.locationMarker) {
	                        var fromPos = this.locationMarker.getPosition();
	                        fromLocation = fromPos.lat() + "," + fromPos.lng();
	                    }
	                    directionsLink = "https://www.google.com/maps/dir/" + fromLocation + "/" + record.lat + "," + record.lng;
	                }

	            listings_html += '<div class="m-dealer js-dealer" data-marker-id="' + i + '">';
	            	listings_html += '<h3>' + name + '</h3>';
	            	if (phone) {
	            		listings_html += '<a class="gtm-find-a-dealer-link gtm-rm-tel" data-dealer-name="' + name +'" href="tel:' + phone.replace(/\D/g,'') + '"><i class="fa fa-phone" aria-hidden="true"></i>' + phone + '</a>';
	            	}
	            	if (website) {
	            		listings_html += '<a class="gtm-find-a-dealer-link" data-dealer-name="' + name +'" target="_blank" href="' + website + '"><i class="fa fa-star" aria-hidden="true"></i>Website</a>';
	            	}
	            	listings_html += '<p>';
	            		if (address) {
	            			listings_html += address + '<br>';
	            		}
	            		
	            		listings_html += (city || '') + (state_code || '') + (postal || '');
	            		
	            	listings_html += '</p>';
	            	if (directionsLink) {
	            		listings_html += '<a class="gtm-find-a-dealer-link" data-dealer-name="' + name +'" target="_blank" href="' + directionsLink + '"><i class="fa fa-share" aria-hidden="true"></i>Directions</a>';
	            	}
	            listings_html += '</div>';
	            dealer_count++;
	           
	            if (dealer_count === tab_size && current_tab < total_pages) {
	            	dealer_count = 0;
	            	current_tab++;

	            	listings_html += '</div>';
	            	listings_html += '<div class="tabs-panel" id="' + current_tab + '">';
	            }
	   		}

	   		if ( total_pages > 1 ) {	
	   			listings_html += '</div></div>';
	   			tabs_additional_class = sm_isOdd(tab_size) ? 'tabs--odd' : ''; 

	   			listings_html += '<ul class="tabs '+ tabs_additional_class + '" data-tabs id="listings-tab">';
		   			for (var i = 1; i <= total_pages; i++) {
		   				additional_class = i == 1 ? 'is-active' : '';
		   				listings_html += '<li class="tabs-title '+ additional_class +'"><a class="tabs-link" href="#' + i + '">' + i + '</a></li>';
		   			}
		   		listings_html += '</ul>';
	   		}

	   		$('.js-dealer-list').html(listings_html);
	   	}

	   	function sm_search_change_handler() {
		    	
		    var new_center = map.getCenter(),
		    	new_bounds = map.getBounds();

		    if (new_bounds && new_center) {
			  	var ne = new_bounds.getNorthEast();
			  	// Calculate radius (in kilometers).
			  	search_radius = google.maps.geometry.spherical.computeDistanceBetween(new_center, ne)/1000;
			}


		    sm_getDealers(new_center, search_radius, function(data) {
	    		dealers_data = data;

	    		if (dealers_data) {
	        		sm_sortDealers(dealers_data, new_center);
	        		sm_buildDealersMarkers(dealers_data, new_center);
	        	}
	    	});
	    	

	    }

	    // Init Map
	    sm_initMap(); 

	    // Click dealer to go to map marker
	    $(document).on("click", ".js-dealer", function() {
	    	var marker_id = $(this).data('marker-id'),
	    		name = allMarkersData[marker_id].name ? allMarkersData[marker_id].name : false;
			
			if (name) {
					infobox.setContent(name);
		    	infobox.open(map, allMarkers[marker_id]);
		    	map.setCenter(allMarkers[marker_id].getPosition()); 
		    	map.setZoom(13);
		    }
		});

	    // Back to full results
		$(document).on("click", ".js-back-to-results", function() {
			if (listings_html != '') {
				$('.js-dealer-list').html(listings_html);
			}
		});

		// Clear search
		$('.js-clear-search').click(function() {
			$(this).removeClass('is-active');
			$('#js-dealer-search').val('');
		});

	    $(window).on("load", function() {
	    	var timeout_id;

	    	// Map scroll, zoom
		    map.addListener('bounds_changed', function(){
		    	if (search_by_geo) {
		    		return;
		    	}
		    	clearTimeout(timeout_id);
				timeout_id = setTimeout(function(){ sm_search_change_handler(); }, 200);
		    });

		});
	});

});